/* HIS */

export const GET_HOSPITAL_FACILITIES =
  "HISSharedManager.GetHospitalFacilityList";

export const GET_OUTPATIENT_ORDERS = "ISMManager.GetWorklistQueue";

export const GET_HOSPITAL_FACILITIES_CLINICS =
  "HISSharedManager.GetHospitalFacilityClinicList";

export const PRINT_ORDER = "PrintManager.PrintOutpatientPrescription";

export const GET_HOSPITAL_PRINTERS = "HISSharedManager.GetHospitalPrinters";
export const SAVE_FLAG = "ISMManager.SaveISMOrderQueue";

export const USER_LOGIN = "ISMManager.Login";

export const API_SAVE_ISM_DEVICECLINIC = "ISMManager.SaveISMDeviceClinics";
export const API_GET_ISM_DEVICECLINIC = "ISMManager.GetISMDeviceClinics";
export const API_GET_COMPUTER_NAME = "ISMManager.GetComputerName";

export const VIEW_PRESCRIPTION = "PrintManager.PatientPrescription";//Added by MA on 15-5-2020

