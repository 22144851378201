// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchJSONUsingPOST } from "../../services/ServiceHelper";
import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD
} from "../../constants/actionTypes";

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed
} from "./actions";

import { GetIP } from "../../helpers/ISMHelpers";
import {
  USER_LOGIN,
  API_GET_COMPUTER_NAME
} from "../../services/ServiceConstants";
import { ServiceToken, baseURL } from "../../services/baseService";

import { formatString } from "../../helpers/ISMHelpers";
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  return fetch(url, options)
    .then(response => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      throw error;
    });
};

/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
  let cookies = new Cookies();
  if (user) cookies.set("user", JSON.stringify(user), { path: "/" });
  else cookies.remove("user");
};

const setUserPicture = UserPicture => {
  localStorage.setItem("UserPicture", UserPicture);
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  try {
    const responsefromAPI = yield call(fetchJSONUsingPOST, USER_LOGIN, {
      UserID: username,
      Password: password
    });
    console.log("LOGGED IN DATA", responsefromAPI);

    let ResponseResult = responsefromAPI.ResponseResult;
    const responseCNAME = "";
    //--midhat uncomment
    //const responseIP = yield call(GetIP);
    // responseCNAME = yield call(
    //   fetchJSONUsingPOST,
    //   API_GET_COMPUTER_NAME,
    //   {
    //     IP: responseIP
    //   }
    // );
    // // console.log("PC VALUE", responseCNAME)
    //----------------------

    if (ResponseResult.LoginStatusID === "Success") {
      console.log("Login Result: ", ResponseResult);
      let response = {
        id: ResponseResult.UserID,
        username: ResponseResult.UserID,
        firstName: ResponseResult.CSSAUserModel.Name,
        lastName: "",
        role: ResponseResult.CSSAUserModel.Role,
        ComputerName: "test", //responseCNAME.ResponseResult,
        UserPictureExists: ResponseResult.CSSAUserModel.UserPictureExists,
        //UserPicture: JSON.stringify(ResponseResult.CSSAUserModel.UserPicture),
        token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4"
      };
      setSession(response);
      setUserPicture(ResponseResult.CSSAUserModel.UserPicture);
      console.log("loginusersuccess*************");
      yield put(loginUserSuccess(response));
    } else {
      yield put(loginUserFailed("Invalid credentials"));
    }
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(loginUserFailed(message));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push("/login");
    });
  } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: "POST",
    headers: { "Content-Type": "application/json" }
  };

  try {
    const response = yield call(fetchJSON, "/users/register", options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: "POST",
    headers: { "Content-Type": "application/json" }
  };

  try {
    const response = yield call(fetchJSON, "/users/password-reset", options);
    yield put(forgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

export function* watchLoginUser()/*: any*/ {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser()/*: any*/ {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser()/*: any*/ {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword()/*: any*/ {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga()/*: any*/ {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword)
  ]);
}

export default authSaga;
