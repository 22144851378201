import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";

export function useTimer(time, callback) {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timerID = setInterval(() => tick(), time);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
    callback();
  }

  return date;
}

export function formatString() {
  // The string containing the format items (e.g. "{0}")
  // will and always has to be the first argument.
  let theString = arguments[0];

  // start with the second argument (i = 1)
  for (let i = 1; i < arguments.length; i++) {
    // "gm" = RegEx options for Global search (more than one instance)
    // and for Multiline search
    let regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
    theString = theString.replace(regEx, arguments[i]);
  }

  return theString;
}

export const SetPreferences = userpreference => {
  if (userpreference === null) return;

  const expires = new Date();
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 365);
  let maxAge = 60 * 60 * 24 * 365;
  console.log(expires);
  let cookies = new Cookies();

  if (userpreference.facility) {
    cookies.set("facility", JSON.stringify(userpreference.facility), {
      path: "/",
      expires,
      maxAge
    });
  }
  if (userpreference.clinic) {
    cookies.set("clinic", JSON.stringify(userpreference.clinic), {
      path: "/",
      expires,
      maxAge
    });
  }
  if (userpreference.printer) {
    cookies.set("printer", JSON.stringify(userpreference.printer), {
      path: "/",
      expires,
      maxAge
    });
  }
  if (userpreference.printerPath) {
    cookies.set("printerPath", JSON.stringify(userpreference.printerPath), {
      path: "/",
      expires,
      maxAge
    });
  }
};

export const GetIP = () => {
  var IP = new Promise(r => {
    var w = window,
      a = new (w.RTCPeerConnection ||
        w.mozRTCPeerConnection ||
        w.webkitRTCPeerConnection)({ iceServers: [] }),
      b = () => { };
    a.createDataChannel("");
    a.createOffer(c => a.setLocalDescription(c, b, b), b);
    a.onicecandidate = c => {
      try {
        c.candidate.candidate
          .match(
            /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
          )

          .forEach(r);
        console.log("GET IP", r)
      } catch (e) {
        console.log("Exception GetIP: ", e);
      }
    };
  });
  return IP;
};


// export const GetIP = () => {
//   var IP = new Promise(r => {
//     // if(!r){
//     //   return null
//     // }
//     var w = window,
//       a = new (w.RTCPeerConnection ||
//         w.mozRTCPeerConnection ||
//         w.webkitRTCPeerConnection)({ iceServers: [] }),
//       b = () => {};
//     a.createDataChannel("");
//     a.createOffer(c => a.setLocalDescription(c, b, b), b);    
//      a.onicecandidate = c => {
//        console.log("cccc: ", c);
//       try {

//         if(c.candidate){
//           console.log("c.candidate.candidate: ", c.candidate.candidate)
//           console.log("c.candidate.candidate typeof: ",c.candidate.candidate
//           .match(
//             /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
//           ))
//           c.candidate.candidate
//           .match(
//             /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
//           )
//           .forEach(r);
//         }
//         else{
//           return null;
//         }

//       } catch (e) {
//         console.log("exception: ",e);
//       }
//     };
//   });
//   console.log("IP: ", IP);
//   return IP;
// };


/*Usage example*/
//GetIP.then(ip => document.write("your ip: ", ip)).catch(e => console.error(e));
