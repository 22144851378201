import keyMirror from "keymirror";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";

// ISM

/* #region  ISM PREFERENCE */
export const SAVE_PREFERENCE = "SAVE_PREFERENCE";
export const LOAD_PREFERENCE = "LOAD_PREFERENCE";
export const SAVE_PREFERENCE_SUCCESS = "SAVE_PREFERENCE_SUCCESS";
export const LOAD_PREFERENCE_SUCCESS = "LOAD_PREFERENCE_SUCCESS";
export const SAVE_PREFERENCE_FAILED = "SAVE_PREFERENCE_FAILED";
export const LOAD_PREFERENCE_FAILED = "LOAD_PREFERENCE_FAILED";
export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED";

/* #endregion */
const actionTypes = keyMirror({
  HIDE_MODAL: null,
  SHOW_MODAL: null
});

export default actionTypes;
