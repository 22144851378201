import { FetchData, FetchDataOldAPI } from "./baseService";
import {
  GET_HOSPITAL_FACILITIES,
  GET_HOSPITAL_FACILITIES_CLINICS,
  GET_OUTPATIENT_ORDERS,
  PRINT_ORDER,
  GET_HOSPITAL_PRINTERS,
  USER_LOGIN,
  SAVE_FLAG,
  API_GET_ISM_DEVICECLINIC,
  GET_PATIENT_EMAIL,
  // API_SAVE_ISM_DEVICECLINIC,//MIDHAT
  VIEW_PRESCRIPTION,//Added by MA on 15-5-2020
} from "./ServiceConstants";

let result = "";

export function GetHospitalFacilityList(resolve, reject) {
  FetchData(GET_HOSPITAL_FACILITIES, "").done(response => {
    result = response.ResponseResult;

    if (result) {
      resolve(result);
    } else {
      reject("No Facilities");
    }
  });
}

export function GetUserDeviceClinic(resolve, reject, requestData) {
  FetchData(API_GET_ISM_DEVICECLINIC, requestData).done(response => {
    result = response.ResponseResult;

    if (result) {
      resolve(result);
    } else {
      reject("No User Preference Saved");
    }
  });
}

export function SaveFlag(resolve, reject, requestData) {
  FetchData(SAVE_FLAG, requestData).done(response => {
    result = response.ResponseResult;

    if (result) {
      console.log(response);
      resolve(result);
    } else {
      reject("Couldnt Save");
    }
  });
}

export function GetOutpatientOrders(resolve, reject, requestData) {
  console.log("getoutpatientorder: ", requestData);
  FetchData(GET_OUTPATIENT_ORDERS, requestData).done(response => {
    result = response.ResponseResult;
    console.log("resulttttt: ", result);
    if (result) {
      resolve(result);
    } else {
      reject("No Orders");
    }
  });
}

export function GetHospitalFacilityClinicsList(resolve, reject, requestData) {
  FetchData(GET_HOSPITAL_FACILITIES_CLINICS, requestData).done(response => {
    result = response.ResponseResult;

    if (result) {
      resolve(result);
    } else {
      reject("No Clinics");
    }
  });
}

export function PrintOrderAPI(resolve, reject, requestData) {
  FetchDataOldAPI(PRINT_ORDER, requestData).done(response => {
    result = response.ResponseResult;
    if (result) {
      resolve(result);
    } else {
      reject(result);
    }
  });
}
export function GetHospitalPrinters(resolve, reject, requestData) {
  FetchData(GET_HOSPITAL_PRINTERS, requestData).done(response => {
    console.log("AMQ",response);
    result = response.ResponseResult;

    if (result) {
      resolve(result);
    } else {
      reject("No Printer");
    }
  });
}

export function LoginUser(resolve, reject, requestData) {
  FetchData(USER_LOGIN, requestData).done(response => {
    result = response.ResponseResult;
    if (result) {
      resolve(result);
    } else {
      reject(result);
    }
  });
}

// Added by MA on 15-5-2020

export function ViewPrescriptionAPI(resolve, reject, requestData) {
  FetchDataOldAPI(VIEW_PRESCRIPTION, requestData).done(response => {
    result = response.ResponseResult;
    if (result) {
      resolve(result);
    } else {
      reject(result);
    }
  });
}
//Addition by MA ends here

//Midhat 

// export function SavePreferenceNew(resolve, reject, requestData) {
//   FetchData(API_SAVE_ISM_DEVICECLINIC
//     , requestData).done(response => {
//       result = response.ResponseResult;


//       if (result) {
//         console.log("USER PREF RESP", response);
//         resolve(result);
//       } else {
//         reject("Couldnt Save");
//       }
//     });
// }