import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  API_SAVE_ISM_DEVICECLINIC,
  API_GET_ISM_DEVICECLINIC
} from "../../services/ServiceConstants";
import { fetchJSONUsingPOST } from "../../services/ServiceHelper";
import { LOAD_PREFERENCE, SAVE_PREFERENCE, SEND_EMAIL } from "../../constants/actionTypes";
import { SetPreferences } from "../../helpers/ISMHelpers";

import { savePreferenceSuccess, savePreferenceFailed, sendEmailSuccess, sendEmailFailed } from "./ismactions";
import { SavePreferenceNew } from "../../services/ISMServices";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */

function* SaveUserPreference({ payload }) {
  try {
    SetPreferences(payload);
    console.log("In Saga : SaveUserPreference");
    console.log(payload);

    let clinics = [];
    let temp_clinic = {};

    payload.clinic.map(function (data, o) {
      temp_clinic = { CLINICID: data.value, DEVICEID: payload.hostname };
      clinics.push(temp_clinic);
    });
    const data = {
      DEVICEID: payload.hostname,
      PRINTERNAME: payload.printer,
      FACILITYID: payload.facility.value,
      Clinics: clinics
    };
    console.log("USER PREF", data)
    const responsefromAPI = yield call(
      fetchJSONUsingPOST,
      API_SAVE_ISM_DEVICECLINIC,
      data
    );
    //MIDHAT
    // const responsefromAPI = new Promise((resolve, reject) => {
    //   SavePreferenceNew(resolve, reject, JSON.stringify(data));
    // });
    //----
    console.log("ISM DATA HERE :::", responsefromAPI);

    yield put(savePreferenceSuccess(payload));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "401 Error Occured";
        break;
      default:
        message = error;
    }
    yield put(savePreferenceFailed(message));
    SetPreferences(null);
  }
}

function* LoadUserPreference({ payload }) {
  try {
    const responsefromAPI = yield call(
      fetchJSONUsingPOST,
      API_GET_ISM_DEVICECLINIC,
      payload
    );

    let clinics = [];
    let temp_clinic = {};
    console.log("ism device clinics");
    console.log(responsefromAPI);
    let ResponseResult = responsefromAPI.ResponseResult;
    if (ResponseResult) {
      if (ResponseResult.Clinics) {
        ResponseResult.Clinics.map(function (datax, o) {
          temp_clinic = { value: datax.CLINICID, label: datax.CLINICNAME };
          clinics.push(temp_clinic);
        });
      }
    }


    const data = {
      hostname: ResponseResult.DEVICEID,
      printer: ResponseResult.PRINTERNAME,
      printerPath: ResponseResult.PRINTERPATH,
      facility: {
        value: ResponseResult.FACILITYID,
        label: ResponseResult.FacilityName
      },
      clinic: clinics
    };

    SetPreferences(data);

    yield put(savePreferenceSuccess(data));
  } catch (error) {
    console.log(error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "401 Error Occured";
        break;
      default:
        message = error;
    }
    yield put(savePreferenceFailed(message));
    SetPreferences(null);
  }
}

function* SendEmail({ payload }) {
  try {
    console.log("In Saga : SendEmail");
    console.log(payload);


    //   const data = {
    //     DEVICEID: payload.hostname,
    //     PRINTERNAME: payload.printer,
    //     FACILITYID: payload.facility.value,
    //     Clinics: clinics
    //   };

    //   const responsefromAPI = yield call(
    //     fetchJSONUsingPOST,
    //     API_SAVE_ISM_DEVICECLINIC,
    //     data
    //   );
    //   console.log(responsefromAPI);

    //   yield put(sendEmailSuccess(payload));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "401 Error Occured";
        break;
      default:
        message = error;
    }
    //   yield put(sendEmailFailed(message));

  }
}

export function* watchSavePreference()/*: any*/ {
  yield takeEvery(SAVE_PREFERENCE, SaveUserPreference);
}

export function* watchLoadPreference()/*: any*/ {
  yield takeEvery(LOAD_PREFERENCE, LoadUserPreference);
}

export function* watchEmail()/*: any*/ {
  yield takeEvery(SEND_EMAIL, SendEmail);
}


function* ismSaga()/*: any*/ {
  yield all([fork(watchSavePreference), fork(watchLoadPreference), fork(watchEmail)]);
}

export default ismSaga;
