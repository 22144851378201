import $ from "jquery";

export const baseURL = "https://hisapimpwebconnectxyz.aku.edu/api";
//export const baseURL = "http://localhost:33158/api";
const baseURLOLDAPI = "https://mypatientswebapi.aku.edu/api/CPOE";
//const baseURLOLDAPI = "http://localhost:33158/api/CPOE";

export const ServiceToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJ3d3cuYWt1LmVkdSIsInN1YiI6Im1ubUBtbm0uY29tIiwiaXNzIjoiTW5NIn0.PRbF6RNmjYm7ai8jQNShBmyDLlZfIWv2Z6V2KosJgjE";

export let FetchData = (RequestMethod, RequestData) => {
  var reqBody = new FormData();
  reqBody.append("RequestMethod", RequestMethod);
  reqBody.append("RequestData", RequestData);

  return $.ajax({
    type: "POST",
    beforeSend: function (request) {
      request.setRequestHeader("_token", ServiceToken);
    },
    url: baseURL,
    data: reqBody,
    processData: false,
    contentType: false,
  });
};

export let FetchDataOldAPI = (RequestMethod, RequestData) => {
  var reqBody = new FormData();
  reqBody.append("requestmethod", RequestMethod);
  reqBody.append("requestdata", RequestData);

  return $.ajax({
    type: "POST",
    beforeSend: function (request) {
      request.setRequestHeader("_token", ServiceToken);
    },
    url: baseURLOLDAPI,
    data: reqBody,
    processData: false,
    contentType: false,
  });
};
