import { combineReducers } from "redux";
import Auth from "./auth/reducers";
import ISM from "./ism/reducers";
// import { reducer as toast } from "material-ui-toast-redux";

// import modal from './modal'
export default combineReducers({
  // modal,
  Auth,
  ISM
  // toast
});
