// @flow
import {
  SAVE_PREFERENCE,
  SAVE_PREFERENCE_SUCCESS,
  LOAD_PREFERENCE
} from "../../constants/actionTypes";

import {
  getCurrentFacility,
  getCurrentPrinter,
  getCurrentClinic,
  getCurrentPrinterPath
} from "../../helpers/authUtils";

const INIT_STATE = {
  userpreference: {
    facility: getCurrentFacility(),
    clinic: getCurrentClinic(),
    printer: getCurrentPrinter(),
    printerPath: getCurrentPrinterPath()
  },
  loading: false
};

// type ISMAction = { type: string, payload: {} | string };
// type State = {
//   userpreference: {} | null
// };

//const ISM = (state: State = INIT_STATE, action: ISMAction) => {

/*type ISMAction = { type: string, payload: {} | any };
type State = {
  userpreference: {} | null,
  loading: boolean
};*/

const ISM = (state/*: State*/ = INIT_STATE, action/*: ISMAction*/) => {
  // const ISM = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_PREFERENCE:
      console.log("In reducer : SAVE_PREFERENCE");
      return {
        ...state,
        loading: true
      };
    case SAVE_PREFERENCE_SUCCESS:
      console.log("In reducer : SAVE_PREFERENCE_SUCCESS");
      console.log(action.payload);
      return {
        ...state,
        userpreference: action.payload,
        loading: false
      };
    case LOAD_PREFERENCE:
      return {
        ...state,
        loading: true
      };
    default:
      return { ...state };
  }
};

export default ISM;
