import { ServiceToken, baseURL } from "./baseService";

export const fetchJSONUsingPOST = (RequestMethod, RequestData) => {
  var reqBody = new FormData();
  console.log("RequestMethod: ", RequestMethod);
  console.log("RequestData: ", RequestData);
  reqBody.append("requestmethod", RequestMethod);
  reqBody.append("requestdata", JSON.stringify(RequestData));


  const options = {
    body: reqBody,
    method: "POST",
    headers: {
      _token: ServiceToken
    }
  };

  return fetch(baseURL, options)
    .then(response => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      throw error;
    });
};
