import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {

  const user = getLoggedInUser();
  console.log("user: ", user);
  if (!user) {
    return false;
  }

  // const decoded = jwtDecode(user.token);

  // const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //   console.warn("access token expired");
  //   return false;
  // } else {
  //   return true;
  // }

  return true;
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get("user");
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

const getUserPicture = () => {
  const UserPicture = localStorage.getItem("UserPicture");
  return typeof UserPicture == "string" ? UserPicture : "";
};
const getCurrentFacility = () => {
  const cookies = new Cookies();
  const facility = cookies.get("facility");
  let response = facility
    ? typeof facility == "object"
      ? facility
      : JSON.parse(facility)
    : null;

  return response;
};
const getCurrentPrinter = () => {
  const cookies = new Cookies();
  const printer = cookies.get("printer");

  return typeof printer == "string" ? printer : "";
};

const getCurrentPrinterPath = () => {
  const cookies = new Cookies();
  const printerPath = cookies.get("printerPath");

  return typeof printerPath == "string" ? printerPath : "";
};

const getCurrentClinic = () => {
  const cookies = new Cookies();
  const clinic = cookies.get("clinic");

  return clinic
    ? typeof clinic == "object"
      ? clinic
      : JSON.parse(clinic)
    : null;
};

export {
  isUserAuthenticated,
  getLoggedInUser,
  getCurrentFacility,
  getCurrentClinic,
  getCurrentPrinter,
  getCurrentPrinterPath,
  getUserPicture
};
