import {
  SAVE_PREFERENCE,
  SAVE_PREFERENCE_SUCCESS,
  LOAD_PREFERENCE_SUCCESS,
  SAVE_PREFERENCE_FAILED,
  LOAD_PREFERENCE_FAILED,
  LOAD_PREFERENCE,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED
} from "../../constants/actionTypes";

/*type ISMAction = { type: string, payload: {} | any };*/

export const savePreference = (userpreference)/*: ISMAction*/ => ({
  type: SAVE_PREFERENCE,
  payload: userpreference
});

export const loadPreference = (hostname)/*: ISMAction*/  => ({
  type: LOAD_PREFERENCE,
  payload: hostname
});

export const savePreferenceSuccess = (userpreference)/*: ISMAction*/  => ({
  type: SAVE_PREFERENCE_SUCCESS,
  payload: userpreference
});

export const savePreferenceFailed = (error)/*: ISMAction*/  => ({
  type: SAVE_PREFERENCE_FAILED,
  payload: error
});

export const loadPreferenceSuccess = (userpreference)/*: ISMAction*/  => ({
  type: LOAD_PREFERENCE_SUCCESS,
  payload: userpreference
});

export const loadPreferenceFailed = (error)/*: ISMAction*/  => ({
  type: LOAD_PREFERENCE_FAILED,
  payload: error
});

export const sendEmail = (userpreference)/*: ISMAction*/  => ({
  type: SEND_EMAIL,
  payload: userpreference
});

export const sendEmailSuccess = (userpreference)/*: ISMAction*/  => ({
  type: SEND_EMAIL_SUCCESS,
  payload: userpreference
});

export const sendEmailFailed = (error)/*: ISMAction*/  => ({
  type: SEND_EMAIL_FAILED,
  payload: error
});